import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NonStretchedImage from "../components/non-stretched-image"

const NarrowImg = styled(NonStretchedImage)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "about" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Us" />
      <h1>About Us</h1>
      <NarrowImg
        fluid={data.allFile.edges[0].node.childImageSharp.fluid}
        alt="Our house in winter time"
      />
      <p>
        We are living with my husband Paavo in the countryside in Luvia near
        Pori in a huge house with just a couple of dogs at home. Almost all my
        owned dogs are living in the homes of their holders, mostly old friends
        and former dog owners. Our two sons have already left our house and are
        having their own life, but very often still visiting us, Johannes,
        graduated from technical university, with his sweet wife Susanna and
        daughter Ellen grandma's jewel and Mattias, who has got his master's
        degree from the commercial university of Turku, now also married with
        Heidi-Miljaana.
      </p>
      <NarrowImg
        fluid={data.allFile.edges[1].node.childImageSharp.fluid}
        alt="Our house in spring time"
      />
      <p>
        In the front page of my sites is "Roope" FIN &amp; S CH Greer's Dazzlin
        Frost my first love in miniature schnauzers. Eija-Maaria Jussila (to
        whom I owe so many thanks about all her knowledge and support in
        breeding and even more about her being my close friend almost forty
        years) brought Roope with her from America and I loved that dog so much
        and decided that some day I shall have one like him. My first mini was
        Roope's granddaughter, a fantastic super dog FIN &amp; S CH Peacemaker's
        Cheri.
      </p>
      <p>
        All our puppies are living family life, they are not left alone for a
        long day, we have always time to play with our puppies, to take them
        also out after the meal. Our puppies learn very easily home neats
        because they have been trained as early as possible. God has helped me
        to breed healthy, long living, and also very often beautiful dogs with
        good character. I am always ready to give all my help when the owners of
        my breedings need some consultation or when they have any need for
        advice.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default AboutUsPage
